<template>
    <div class="service-rt">
        <!-- 编号 -->
        <div class="rt-item">
            <span class="item-title">商品编号：</span>
            <span>{{detail.id}}</span>
        </div>
        <!-- 价格 -->
        <div class="rt-item">
            <span class="item-title">价格：</span>
            <span class="item-price">{{detail.price}} 元 / {{detail.unit}}</span>
        </div>
        <!-- 地区 -->
        <div class="rt-item">
            <span class="item-title">所在地区：</span>
            <span>{{detail.area && detail.area.join(' ')}}</span>
        </div>
         <p id="type" hidden=true>{{detail.goodsType}}</p>
        <template v-if="detail.goodsType == 1">
            <!-- 时间 -->
            <div class="rt-item">
                <span class="item-title">日期：</span>
                <el-date-picker
                    v-model="form.startDate"
                    type="date"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    @change="handleDate"
                    :picker-options="startOptions">
                </el-date-picker>
            </div>
            <div class="rt-item">
                <span class="item-title">时间：</span>
                <el-time-select
                    palceholder="开始时间"
                    v-model="form.startTime"
                    :picker-options="{
                        start: detail.open_begin,
                        step: '01:00',
                        end: detail.open_end,
                        minTime: time_picker
                    }">
                </el-time-select>
            </div>
            <div class="rt-item rt-dresser">
                <span class="item-title">化妆师：</span>
                <ul class="dresser-need">
                    <li>
                        <a href="javascript:;"
                            :class="{'is-need': form.need}"
                            @click="form.need = true">
                            需要
                        </a>
                    </li>
                    <li>
                        <a href="javascript:;"
                            :class="{'is-need': !form.need}"
                            @click="form.need = false">
                            不需要
                        </a>
                    </li>
                </ul>
            </div>
        </template>
        <!-- 立即下单 -->
        <el-button
            size="medium"
            @click="handleSubmit"
            class="rt-btn">
            立即下单
        </el-button>
        <!-- 发布者 -->
        <div class="rt-title rt-item">
            发布者信息
        </div>
        <div class="rt-own">
            <!-- 头像 -->
            <el-image
                :src="own.avatar || ''"
                fit="scale-down"
                class="own-avatar">
                <!-- 加载中样式 -->
                <template v-slot:placeholder>
                    <div class="image-slot">
                        <i class="el-icon-loading"></i>
                    </div>
                </template>
                <!-- 加载失败样式 -->
                <template v-slot:error>
                    <div class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                    </div>
                </template>
            </el-image>
            <!-- 信息 -->
            <div class="own-info">
                <div class="info-top">
                    <span class="info-name">
                        {{own.name}}
                    </span>
                    <span class="gender-man"
                        v-if="own.gender == 1">
                    </span>
                    <span class="gender-girl"
                        v-else>
                    </span>
                    <!-- <span class="info-tags"
                        v-for="(item, index) in 2"
                        :key="index">
                        斜杠
                    </span> -->
                </div>
                <div class="info-sign">
                    {{own.signature}}
                </div>
            </div>
        </div>
        <!-- 相关发布 -->
        <div class="rt-title rt-item">
            ta的相关发布
        </div>
        <div class="rt-about">
            <slot name="about"></slot>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                form: {
                    startDate: '',
                    startTime: '',
                    need: true,
                },
                rules:{
                    startDate: [
                    {required: true, message: '请选择开始时间', trigger: 'blur'}
                ]
                },
                startOptions: {
                    disabledDate: (time) => {
                        let now = new Date();
                        return time.getTime() < now.setDate(now.getDate()-1);
                    }
                },
                endOptions: {
                    disabledDate: (time) => {
                        let now = new Date(),
                        date = new Date(this.form.start_date);
                        return time.getTime() < date || time.getTime() < Date.now();
                    }
                }
            }
        },
        props: {
            detail: {
                type: Object,
                default() {return {}}
            },
            own: {
                type: Object,
                default() {return {}}
            }
        },
        computed: {
            time_picker(){
                let now_time = new Date();
                let selected_time = new Date(this.form.startDate);
                let today = new Date(new Date().setHours(0, 0, 0, 0));
                let tomorrow = today.setDate(today.getDate()+2); // 时间戳

                let afterTwo = new Date(now_time.getTime()), // 当前时间往后推两小时（根据需求修改为去掉往后推的两小时）
                after_hours = afterTwo.getHours(), 
                after_minutes = afterTwo.getMinutes(),
                h = after_hours < 10? ('0' + after_hours): after_hours,
                m = after_minutes < 10? ('0' + after_minutes): after_minutes;

                let begin_arr = [], end_arr = [];
                if(this.detail.open_begin) begin_arr = this.detail.open_begin.split(":"); // 服务器返回开始时间
                if(this.detail.open_end) end_arr = this.detail.open_end.split(":"); // 服务器返回结束时间

                let param_time = ""; // 返回参数
                
                if(selected_time.getTime() < tomorrow){ // 今天
                    // 
                    if(after_hours < begin_arr[0])  
                    {
                        param_time = begin_arr[0] - 1 + ":" + begin_arr[1];
                    }
                    if(after_hours > end_arr[0])  
                    {
                        param_time = this.detail.open_end;
                    }
                    if(after_hours >= begin_arr[0] && after_hours <= end_arr[0]) {
                        param_time = h + ":" + m;
                    }
                }else{ // 第二天及之后
                    param_time = begin_arr[0] - 1 + ":" + begin_arr[1];
                }
                return param_time;
            }
        },
        methods: {
            handleDate(val){
            },
            handleSubmit() {
                let type=document.getElementById("type").innerHTML;
                if((this.form.startDate==null||this.form.startTime=="")&&type == 1){
                        this.$message({
                             showClose: true,
                            message: "请选择时间",
                            type: "error"
                        })
                }else{
                      this.$emit('handle-click', this.form)
                }
              
            }
        }
    }
</script>

<style scoped>
.rt-item {
    margin-bottom: 16px;
}
.rt-dresser {
    display: flex;
    align-items: center;
}
.dresser-need {
    flex: 1;
    display: flex;
}
.dresser-need a {
    display: block;
    padding: 10px 20px;
    border-radius: 20px;
    color: #333;
}
.dresser-need a.is-need {
    background-color: #e6a23c;
    color: #fff;
}
.item-title {
    margin-right: 10px;
    font-weight: bold;
}
.item-price {
    font-size: 18px;
    color: #E6A23C;
}
.rt-btn {
    width: 100%;
    margin-bottom: 60px;
    background-color: #8d9afc;
    border-color: #8d9afc;
    color: #fff;
}

/* 发布者 */
.rt-title {
    color: #909399;
}
.rt-own {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
}
.own-avatar {
    width: 80px;
    height: 80px;
    margin-right: 10px;
    border-radius: 50%;
    overflow: hidden;
}
.own-info {
    flex: 1;
    width: 0;
}
.info-top {
    display: flex;
    align-items: center;
}
.info-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.gender-man,
.gender-girl{
    flex-shrink: 0;
    width: 16px;
    height: 16px;
    margin-left: 6px;
    margin-right: 16px;
}
.gender-man {
    background-image: url("../assets/icons/icon_male.png");
}
.gender-girl {
    background-image: url("../assets/icons/icon_girl.png");
}
.info-tags {
    padding: 0 6px;
    background-color: #8d9afc;
    font-size: 12px;
    color: #fff;
}
.info-tags + .info-tags {
    margin-left: 6px;
}
.info-sign {
    margin-top: 6px;
    color: #909399;
}
.rt-about > div + div {
    margin-top: 10px;
}
</style>